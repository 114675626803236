<template>
  <b-container fluid class="vh-100 overflow-y-scroll app-container p-0">
    <router-view />
    <footer-section :theme="footerTheme" />
  </b-container>
</template>

<script>
import { BContainer } from "bootstrap-vue";

import FooterSection from "@/components/Layouts/FooterSection";

export default {
  name: "Home",
  components: { BContainer, FooterSection },
  computed: {
    isHomepage() {
      return this.$route.name === "Home";
    },
    isPricing() {
      return this.$route.name === "Pricing";
    },
    isDarkmode() {
      return this.$store.state.customize["dark-mode"] === "dark";
    },
    footerTheme() {
      if (this.isHomepage) return "dark";
      if (this.isPricing) return "dark";
      return this.isDarkmode ? "dark" : "beige";
    },
  },
};
</script>

<style scoped lang="scss">
.app-container {
  background-color: #18191f;
  min-height: 100vh;
}
</style>
