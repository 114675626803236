<template>
  <footer class="footer-section" data-cy="footer" :class="['theme-' + theme]">
    <b-row no-gutters class="py-4 py-lg-5 px-3">
      <b-col cols="0" lg="1"></b-col>

      <b-col cols="12" lg="5" class="justify-content-center justify-content-lg-start d-flex align-items-center mb-3 mb-lg-0">
        <p class="m-0" data-cy="copyright">
          <small class="smaller"><strong>&copy;STOPLEADS OÜ</strong> - Sepapaja 6, Tallinn 15551 Estonia </small>
        </p>
      </b-col>

      <b-col
        cols="12"
        lg="5"
        class="justify-content-center justify-content-lg-end d-flex align-items-center"
        data-cy="footer-menu"
      >
        <div class="text-center text-lg-right">
          <small class="smaller">
            <router-link :to="{ name: 'CookiesPolicy' }">Cookies Policy</router-link> |
            <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link> |
            <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link>
          </small>
        </div>
      </b-col>

      <b-col cols="0" lg="1"></b-col>
    </b-row>
  </footer>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "FooterSection",
  components: { BRow, BCol },
  props: {
    theme: {
      type: String,
      default: "beige",
      validator: (v) => ["beige", "dark"].includes(v),
    },
  },
  computed: {
    isMobile() {
      return !this.$screen.is("md");
    },
  },
};
</script>
